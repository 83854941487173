@import 'src/styles/variables';

.maintoolbar {
  background-color: $main-toolbar-background;
}

.maintoolbar span {
  color: $main-toolbar-color;
}

.maintoolbar a {
  color: $main-toolbar-color;
  border: 1px solid transparent;
  cursor: default;
}

.maintoolbar a:active {
  background-color: $main-toolbar-hover-border;
}

.maintoolbar .navlink-hover {
}

.maintoolbar .navlink-hover:hover {
  background-color: $main-toolbar-hover-background;
  border: 1px solid $main-toolbar-hover-border;
}

.maintoolbar .navlink-hover:active {
  background-color: $main-toolbar-hover-border;
}

.maintoolbar .navbar-brand img {
  height: 1.8rem;
  border: none;
  margin-right: 0.4rem;
  padding-bottom: 0.2rem;
}

.maintoolbar a.navbar-brand:hover {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  cursor: pointer !important;
}

.maintoolbar a.navbar-brand:active {
  background-color: transparent !important;
}

.maintoolbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(#{red($main-toolbar-color)}, #{green($main-toolbar-color)}, #{blue($main-toolbar-color)}, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.maintoolbar .navbar-toggler {
  border: 1px solid $main-toolbar-color;
}

.maintoolbar .navbar-toggler:focus {
  box-shadow: 0 0 0 0;
}

.maintoolbar .navbar-toggler.collapsed {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.maintoolbar .nav-link {
  padding: 0.25rem 0.75rem;
  color: $main-toolbar-color !important;
}

.maintoolbar .dropdown-menu {
  background-color: $main-toolbar-background;
  padding: 0.1rem 0;
  margin: 0;
  border: 1px solid $main-toolbar-hover-border;
  border-radius: 0;
  z-index: 1035;
}

.maintoolbar .navbar-nav .show > .nav-link {
  background-color: $main-toolbar-hover-border;
  border: 1px solid $main-toolbar-hover-border;
}

.maintoolbar .dropdown-item {
  background-color: $main-toolbar-background;
  color: $main-toolbar-color !important;
}

.maintoolbar .dropdown-item:hover {
  background-color: $main-toolbar-hover-background;
  border: 1px solid $main-toolbar-hover-border;
}

.maintoolbar .dropdown-submenu {
  position: relative;
}

.maintoolbar .dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: 1rem;
}

.maintoolbar .dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: 0rem;
  margin-right: .1rem;
}

.maintoolbar .quickfunctions {
  margin: 0;
}

.maintoolbar .quickfunctions .nav-link img {
  height: 2.6rem;
  border: none;
}

.maintoolbar .quickfunctions .nav-link {
  padding: 0;
  color: $main-toolbar-color !important;
}

.maintoolbar .quickfunctions .dropdown-header {
  font-size: 1rem;
  font-weight: 500;
  color: $main-toolbar-color;
}

.maintoolbar .quickfunctions .show > .nav-link {
  background-color: $main-toolbar-hover-background;
  border: 1px solid $main-toolbar-hover-border;
}
