.esoes-progress {
  display:block;
}

.esoes-progress-bar {
  height: 100%;
}

.esoes-progress-span {
  text-align: center;
  top:0px;
}



