@import 'src/styles/variables';

.breadcrumb {
  padding: 0rem 1rem;
  margin: 0 0 0 0.2rem;
  background-color: $breadcrumb-background;
  border-radius: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: $breadcrumb-color;
  font-weight: $breadcrumb-font-weight;
  content: ">";
}

.breadcrumb-item {
  color: $breadcrumb-color;
  font-weight: $breadcrumb-font-weight;
}

.breadcrumb-item.active {
  color: $breadcrumb-color;
  font-weight: $breadcrumb-font-weight;
}

.breadcrumb a {
  color: $breadcrumb-link-color;
  text-decoration: underline;
  font-weight: $breadcrumb-font-weight;
}

.breadcrumb-line {
  height: 0.2rem;
  background-color: $breadcrumb-background;
}

.breadcrumb-marging-ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS */
    margin-bottom: -5px;
  }
}
