@import 'src/styles/variables';

.detail-area {
  background-color: $detail-area-background;
}

.linkbutton {
  text-decoration: underline !important;
  color: #0366d6 !important;
  cursor: pointer !important;
}

.infolabel {
  border: 1px solid #ced4da;
  padding: 2px 6px;
}

.display-none-important {
  display: none !important;
}

// dont need anymore, Apple fixed implementation
.vh-100-safari {

}

/* dont need anymore, Apple fixed implementation
.vh-100-safari {
  @media not all and (min-resolution: .001dpcm) {
    height: 100vh !important;
  }
}

.h-100-notsafari {
  @media all and (min-resolution: .001dpcm) {
    height: 100% !important;
  }
}

.h-unset-safari {
  @media not all and (min-resolution: .001dpcm) {
    height: unset !important;
  }
}
*/
