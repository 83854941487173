.modal-backdrop.show {
  opacity: $backdrop-opacity;
}

.modal-header {
  background: whitesmoke;
  padding: 0.25rem 0.75rem;
}

.modal-body {
  padding: 1rem;
  padding-bottom: 0;
}

.modal-footer {
  padding-top: 1rem;
  padding-bottom: 0.7rem;
  padding-right: 0;
}

.modal-center {
  padding-bottom: 0.6rem;
}

.dialog-button {
  width: 6rem;
}

.ng-touched.ng-invalid:not(form) {
  border: solid red;
}
