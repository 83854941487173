@import 'src/styles/variables';

html, body {
  height: 100%;
}

.bg-mainwindow {
  background-color: $main-window-background;
}

:focus {
  outline-color: transparent !important;
  outline-style: none !important;
  outline-width: 0 !important;
}

@import 'src/styles/maintoolbar';
@import 'src/styles/detailtoolbar';
@import 'src/styles/breadcrumb';
@import "src/styles/dxdatebox";
@import 'src/styles/dxgrid';
@import 'src/styles/modal';
@import "src/styles/tooltip";
@import "src/styles/common";
@import "src/styles/detailpanel";
@import "src/styles/progressbar";

.spinner-grow {
  -webkit-animation: spinner-grow 1.2s linear infinite;
  animation: spinner-grow 1.2s linear infinite;
}

#adalIdTokenFrame {
  border: 0;
}
