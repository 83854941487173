@import 'src/styles/variables';

.dx-datagrid .dx-datagrid-headers .dx-header-row > td {
  color: $dxgrid-color;
  background-color: $dxgrid-header-background;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell {
  text-align: center !important;
}

@media (min-device-width: 769px) {
  .dx-datagrid .dx-row > td {
    padding: 3px;
  }
}

.dx-datagrid-header-panel {
  background-color: $dxgrid-header-background;
}

.dx-datagrid .dx-toolbar-items-container {
  background-color: $dxgrid-header-background;
}

.dx-datagrid-header-panel .dx-toolbar {
  margin: 5px 0
}

@media (min-device-width: 769px) {
  .dx-datagrid-headers .dx-texteditor-input, .dx-datagrid-rowsview .dx-texteditor-input {
    padding: 3px;
    min-height: unset;
  }
}

@media (min-device-width: 769px) {
  .dx-filter-menu.dx-menu .dx-menu-item .dx-menu-item-content {
    padding: 3px;
  }
}


.dx-datagrid-total-footer > .dx-datagrid-content {
  padding-top: 0;
  padding-bottom: 0;
}


.dx-datagrid-summary-item {
  font-weight: normal;
  color: $dxgrid-color;
}

.grid-progress {
  background-color: #808080;
  height: 1.2rem;
  font-size: 0.9rem;
}

.grid-progress-text {
  color: white;
}

.cls {
  background-color: $dxgrid-row-markup;
}

.cellEntry {
  background-color: $dxgrid-row-entry-markup;
}

.dx-datagrid-filter-panel {
  background-color: $dxgrid-filter-panel-background-color;
}
