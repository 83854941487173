/* Main window */
$main-window-background: #A5B1C1;

/* Detail area */
$detail-area-background: #DEE0E3;

/* Main toolbar */
$main-toolbar-color: #FFFFFF;
$main-toolbar-background: #586575;
$main-toolbar-hover-background: #A5B1C1;
$main-toolbar-hover-border: #BCC1C8;


/* Detail toolbar */
$detail-toolbar-color: #FFFFFF;
$detail-toolbar-background: #1E3C63;
$detail-toolbar-hover-background: #A5B1C1;
$detail-toolbar-hover-border: #BCC1C8;
$detail-toolbar-pressed-background: #BCC1C8;
$detail-toolbar-menu-hover-background: #A5B1C1;

/* Breadcrumb */
$breadcrumb-color: #FFFFFF;
$breadcrumb-link-color: #FFFFFF;
$breadcrumb-background: #E51630;
$breadcrumb-font-weight: 500;

/* DX Grid */
$dxgrid-color: #333333;
$dxgrid-filter-panel-background-color: #FFFFFF;
$dxgrid-header-background: #F6F6F6;
$dxgrid-row-markup: #d4d4d4;
$dxgrid-row-entry-markup: #acd4fa;

/* Login */
$login-window-background-color: $detail-area-background;
$login-border-color: #A5B1C1;
$login-background-color: #FFFFFF;
$login-header-background-color: #586575;
$login-header-color: #FFFFFF;

/* Wait cursor */
//$waitcursor-border-color: #606060; /* Circle color */
//$waitcursor-border-left-color: #FFAC59; /* Circle color */
$waitcursor-border-color: #449BDD; /* Circle color */
$waitcursor-border-left-color: #63F3FE; /* Circle color */
$waitcursor-size: 5em;

/* Modal */
$backdrop-opacity: 0.2;

/* Group tile colors */
$tile-background-color-Group1: #007eca;
$tile-background-color-Group2: #9c00d6;
$tile-background-color-Group3: #118a11;
$tile-background-color-Group4: #646464;
$tile-background-color-Group5: #ffa500;
$tile-background-color-Group6: #7ca7d1;
$tile-subtitle-color: #eae8e8;
$tile-group-title-color: #828282;

/* Splitter */
$splitter-background-color: #586575;

/* Detail panel */
$detailpanel-background-color: whitesmoke;
