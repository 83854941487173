.errortooltip .tooltip-inner {
  background-color: #F1F2F7;
  border: 1px solid #767676;
  color: #515151;
  text-align: left;
  white-space: nowrap;
  max-width: none;
}

.tooltip.show {
  opacity: 1;
}

.errortooltip .arrow::before {
  border-top-color: red;
}
