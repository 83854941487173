@import 'src/styles/variables';

.bg-detailtoolbar {
  background-color: $detail-toolbar-background;
}

.detailarea {
  background-color: $dxgrid-header-background;
}

.detailtoolbar {
  background-color: $detail-toolbar-background;
  overflow-x: auto;
  padding: 0.1rem;
  margin: 0 0 0 0;
}

@media (min-device-width: 769px) {
  .detailtoolbar {
    overflow-x: hidden;
  }
}

.detailtoolbar a {
  color: $detail-toolbar-color;
  border: 1px solid transparent;
  cursor: default;
}

.detailtoolbar a:active {
  background-color: $detail-toolbar-pressed-background;
}

.detailtoolbar .navlink-hover {
}

.detailtoolbar .navlink-hover:hover {
  background-color: $detail-toolbar-hover-background;
  border: 1px solid $detail-toolbar-hover-border;
}

.detailtoolbar .navlink-hover:active {
  background-color: $detail-toolbar-pressed-background;
}

.detailtoolbar .dropdown-item {
  background-color: $detail-toolbar-background;
}

.detailtoolbar .dropdown-menu a:hover {
  background-color: $detail-toolbar-menu-hover-background;
  border: 1px solid $detail-toolbar-background;
}

.detailtoolbar .dropdown-item a:active {
  background-color: $detail-toolbar-pressed-background;
}

.detailtoolbar .nav-link {
  padding: 0.25rem 0.75rem;
  color: $detail-toolbar-color !important;
  white-space: nowrap !important;
}

.detailtoolbar .nav-link.active {
  background-color: $detail-toolbar-pressed-background;
  border: 1px solid $detail-toolbar-hover-border !important;
}

.detailtoolbar .dropdown-menu {
  background-color: $detail-toolbar-background;
  padding: 0.1rem 0;
  margin: 0;
  border: 1px solid $detail-toolbar-background;
  border-radius: 0;
}

.detailtoolbar .navbar-nav .show > .nav-link {
  background-color: $detail-toolbar-pressed-background;
  border: 1px solid $detail-toolbar-hover-border;
}

.detailtoolbar img {
  height: 1.5rem;
  border: none;
  margin-right: 0.4rem;
}


