@import 'src/styles/variables';

.detailpanel {
  background-color: $detailpanel-background-color;
  overflow: auto;
}

.detailpanel-cardheader {
  font-size: 0.9rem;
  font-weight: 500;
}
